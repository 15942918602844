// 考试详情
// 专项考试列表
<template>
  <div class="pageContol listWrap templateList specialTestDetail">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.push('/web/specialTestList')"
            >专项考试</a
          >
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">考试详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="info_box">
          <div class="title">考试情况汇总</div>
          <div class="info">
            <div class="flexcb">
              <p>
                <span class="label">最&emsp;低&emsp;分：</span>
                <span style="width: 100px">{{ Summary.minScore }}</span>
              </p>
              <p>
                <span class="label">最&emsp;高&emsp;分：</span>
                <span style="width: 100px">{{ Summary.maxScore }}</span>
              </p>
              <p>
                <span class="label">平均分：</span>
                <span style="width: 100px">{{ Summary.averageScore }}</span>
              </p>
            </div>
            <div class="flexcb">
              <p>
                <span class="label">应考人数：</span>
                <span style="width: 100px">{{ Summary.shouldNum }}</span>
              </p>
              <p>
                <span class="label">实&emsp;考&emsp;人&emsp;数：</span>
                <span style="width: 100px">{{ Summary.actualNum }}</span>
              </p>
              <p>
                <span class="label">参考率：</span>
                <span style="width: 100px">{{ Summary.referenceRate }}</span>
              </p>
            </div>
            <div class="flexcb">
              <p>
                <span class="label">合格人数：</span>
                <span style="width: 100px">{{ Summary.qualifiedNum }}</span>
              </p>
              <p>
                <span class="label">不合格人数：</span>
                <span style="width: 100px">{{ Summary.noQualifiedNum }}</span>
              </p>
              <p>
                <span class="label">合格率：</span>
                <span style="width: 100px">{{ Summary.passRate }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                size="small"
                clearable
                placeholder="请输入姓名"
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="idcard"
                type="text"
                size="small"
                clearable
                placeholder="请输入身份证号"
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="mobile"
                type="text"
                size="small"
                clearable
                placeholder="请输入手机号"
              />
            </div>
            <div class="btnBox" style="margin-left: 20px">
              <el-button
                style="margin-left: 20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="doExport">导出</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <!-- <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              /> -->
              <el-table-column
                label="姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.userName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="身份证号"
                align="left"
                prop="idcard"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.idcard || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="手机号"
                align="left"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.mobile || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="考试次数"
                align="left"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.examNum || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="最高成绩"
                align="center"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.examScore }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import html2canvas from "html2canvas";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "specialTestDetail",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      Summary: {},
      mobile: "",
      userName: "",
      idcard: "",
    };
  },
  computed: {},
  created() {
    // console.log(this.pageNum);
    this.getTableHeight();
    this.examId = this.$route.query.examId;
    this.getDetail(this.examId);
  },
  methods: {
    getParams() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        examId: this.$route.query.examId,
        mobile: this.mobile,
        userName: this.userName,
        idcard: this.idcard,
      };
      return params;
    },
    doExport() {
      this.$post("/biz/exam/user/listExport", this.getParams()).then((res) => {
        ;
        if (res.status == "0") {
          window.open(res.data);
        }
      });
    },
    getDetail(examId) {
      this.$post("/biz/exam/user/details/queryExamDetailStatistic", {
        examId: this.examId,
      }).then((res) => {
        ;
        if (res.status == "0") {
          this.Summary = {
            ...res.data,
          };
        }
      });
    },
    getData(pageNum = 1) {
      const params = this.getParams();
      this.doFetch({
        url: "/biz/exam/user/pageList",
        params,
        pageNum,
      });
    },

    //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 6) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 5;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {},
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.specialTestDetail {
  .info_box {
    padding: 10px 20px;
    background-color: #f2f7fd;
    border-radius: 8px;
    margin-bottom: 10px;
    .title {
      font-size: 16px;
      font-weight: 600;
    }
    .info {
      padding: 10px 50px;
      > div {
        margin-bottom: 8px;
        .label {
          min-width: 80px;
          text-align: right;
        }
      }
      > div:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>

